import "./bootstrap";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import datepickerEUFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-vi';
import Alpine from "alpinejs";
import "slick-carousel";
import "lazysizes";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import Swal  from "sweetalert2";
import AOS from "aos";
import "flowbite";
import "./fullpage";
import "./fullpage.extensions";
import Swiper from 'swiper/bundle';
import "select2";[]
import 'daterangepicker';

window.$ = window.jQuery = $;
window.AOS = AOS;
window.Fancybox = Fancybox;
window.Swal = Swal;
window.Alpine = Alpine;
window.Swiper = Swiper;
datepickerFactory($);
datepickerEUFactory($);

// Initialise jQuery
$(function () {
    // Any jQuery scripts...
    AOS.init({
        // once: true,
    });
    Fancybox.bind("[data-fancybox]", {});
    Alpine.start();
});


$(".button_search").on("click", function() {
    $(".show__search").toggle();
});
/* Hiệu ứng chạy chữ tìm kiếm */
$(document).ready(function() {
    const message = "Bạn muốn tìm kiếm gì ?";
    let index = 0;

    function type() {
        if (index < message.length) {
            $('.animation__search').attr('placeholder', message.substring(0, index + 1));
            index++;
            setTimeout(type, 100);
        } else {
            setTimeout(resetType, 1000);
        }
    }

    function resetType() {
        index = 0;
        type();
    }
    type();
});

$(".btn__search-info").on("click", function(event) {
    event.stopPropagation();
    $(".show__search").toggle();
});

$(document).click(function(event) {
    if (!$(event.target).closest(".show__search").length) {
        $(".show__search").fadeOut(600);
    }
});
